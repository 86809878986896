import axios from "axios";
const url = "https://v2.api.bookedsolid.co.uk";
// const url = 'http://localhost:5002';
let client_id = localStorage.client_id || '';

// Modify alert
window.alert = function (message) {
  const alert = document.getElementById('alert');
  const hide = () => alert.style.display = 'none';
  document.getElementById('alert-text').innerText = message;
  alert.style.display = 'block';
  document.addEventListener("keyup", function (event) {
    if (event.keyCode === 13) hide();
  });
  setTimeout(hide, 6000);
}

/***********  Admin account functions ***********/

// Login a user
async function login(email, password) {
  const data = {
    email,
    password,
  };
  // send request to server
  const response = await axios.post(`${url}/admin/login`, data)
    .catch((err) => { alert(err?.response?.data?.message || 'Incorrect email or password'); });
    console.log(response.data)
  if (response?.status == 200) {
    // if successful, add session to localStorage
    localStorage.name = response.data.resName;
    localStorage.email = response.data.resEmail;
    if (response.data.isv) {
      localStorage.isvSession = response.data.token;
      sessionStorage.isvSession = response.data.token;
      localStorage.isv_id = response.data.isv._id;
      localStorage.adminSession = response.data.isv.subaccounts[0].tokens[0].token;
      sessionStorage.adminSession = response.data.isv.subaccounts[0].tokens[0].token;
      localStorage.client_id = response.data.isv.subaccounts[0]._id;
    } else {
      localStorage.adminSession = response.data.token;
      sessionStorage.adminSession = response.data.token;
      localStorage.client_id = response.data.client_id;
      client_id = response.data.client_id;
    }
    localStorage.registrationDate = response.data.registrationDate;
    window.dispatchEvent(new Event('settings_updated'));
    window.location.href = "/messages";
  } else {
    return false;
  }
}

// Get an ISV account
async function getISV() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.isvSession}`
    },
  });
  const response = await instance.get(`/isv`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.user;
}

// Create a client account under an ISV
async function addClient(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.isvSession}`
    },
  });
  const response = await instance.post(`/isv/client`, data);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return response.data;
}

// Login a user
async function register(name, email, password, integration, referralSource) {
  const data = {
    name,
    email,
    password,
    integration,
    referralSource
  };
  // send request to server
  const response = await axios.post(`${url}/client/register`, data)
    .catch((err) => { alert(err?.response?.data?.message || 'Registration failed. Please try again later, or email contact@bookedsolid.co.uk'); });
  if (response?.status == 200) {
    // if successful, add session to localStorage
    localStorage.adminSession = response.data.token;
    sessionStorage.adminSession = response.data.token;
    localStorage.name = response.data.resName;
    localStorage.email = response.data.resEmail;
    localStorage.client_id = response.data.client_id;
    client_id = response.data.client_id;
    window.dispatchEvent(new Event('settings_updated'));
    return true;
  } else {
    return false;
  }
}

// Request the resetting of a password
async function forgotPassword(email) {
  if (!email) alert('Please enter your email');
  else {
    const data = {
      email
    };
    // send request to server
    const response = await axios.post(`${url}/client/forgot_password`, data);
    console.log(response);
  }
}

// Reset the password
async function resetPassword(token, new_password, confirm_password) {
  const data = {
    new_password,
    confirm_password
  };
  const response = await axios.post(`${url}/client/reset_password/${token}`, data)
    .catch((err) => { return err.response.data; });
  if (response) return response.data;
}

// Get client settings
async function getClientSettings() {
  if (!client_id) return false;
  const response = await axios.get(`${url}/client/settings/${client_id}`,);
  return response?.data?.settings || false;
}

// Update client settings
async function updateClientSettings(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/client/settings/${client_id}`, data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error updating settings');
    return false;
  }
}

// Check integrations
async function checkIntegrations() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/client/integrations`);
  console.log(response.data)
  return response?.data?.integrations || false;
}

// Update integrations
async function updateIntegrations(integrations) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/client/integrations`, integrations).catch(err => console.log(err));
  if (response?.data?.success) return response?.data;
  else {
    return false;
  }
}

// Create a payment
async function createPayment(integrations) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/client/create-payment`, {});
  if (response?.data?.url) window.location.href = response.data.url;
  else {
    alert(response?.data?.message || 'Error starting payment. Please contact us to continue.');
    return false;
  }
}

// Get availabilities
async function getAvailabilities(refresh = false) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/client/availabilities?refresh=${refresh}`);
  return response?.data?.availabilities || false;
}

// Update availabilities
async function updateAvailabilities(availabilities) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/client/availabilities`, {availabilities});
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error updating settings');
    return false;
  }
}

// Initiate Square OAuth
async function squareOauth() {
  const response = await axios.get(`${url}/admin/square/oauth`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  if (response?.data?.success) window.location.href = response.data.url;
  else {
    alert(response?.data?.message || 'Error initiating Square OAuth');
    return false;
  }
}

// Revoke Square OAuth
async function revokeSquare() {
  const response = await axios.delete(`${url}/admin/square/revoke`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error revoking Square OAuth');
    return false;
  }
}

// Request access to AI
async function requestAIAccess(feature) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = { feature };
  const response = await instance.post(`/client/ai/request`, data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error requesting access');
    return false;
  }
}

async function getMessages() {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get(`/admin/messages`).catch(err => console.log(err));
  console.log(response)
  return response.data.messages;
}

async function addData(message, model) {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.post(`/admin/data`, { message, model });
  return response.data.success;
}

async function getData() {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get(`/admin/data`);
  console.log(response.data)
  return response.data.data;
}

async function removeData(data) {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/admin/data`, { data });
  return response.data.data;
}

async function removeMessageFromData(data) {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/admin/data/message`, data);
  return response.data.data;
}

/***********  Messaging functions ***********/

// Get dashboard data
async function getDashboard() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/homepage/dashboard`).catch(err => {
    console.log(err.response.data)
    if (err.response.status === 401) window.location.href = '/login';
    else return [];
  });
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.data;
}

// Get all conversations for a client
async function getConversations() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/ai/messaging/conversations`).catch(err => {
    console.log(err.response.data)
    if (err.response.status === 401) window.location.href = '/login';
    else return [];
  });
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.conversations;
}

// Get a single conversation
async function getConversation(contact) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/ai/messaging/conversations/${contact}`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.messages;
}

// Send a message
async function sendMessage(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/messaging/send`, data);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Unpause an account
async function unpauseAccount(contact) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/messaging/unpause`, { contact });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Get call history
async function getCalls(page) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/ai/voice/calls?page=${page}`).catch(err => {
    console.log(err.response.data)
    if (err.response.status === 401) window.location.href = '/login';
    else return [];
  });
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data;
}

// Mark a call as read
async function markCallRead(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/voice/read`, { id });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Like a call
async function likeCall(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/voice/like-call`, { id });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Dislike a call
async function dislikeCall(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/voice/dislike-call`, { id });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

/***********  Info functions ***********/

// Get all faqs
async function getInfo() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/info`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.data;
}

// Add an faq
async function addInfo(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/info`, data);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Upload a knowledge base file
async function uploadInfo(formData) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  const response = await instance.post(`/info/upload`, formData);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Delete an faq
async function deleteInfo(info_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.delete(`/info/${info_id}`);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Generate FAQs from knowledge base
async function generateInfo(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/info/generate`, data).catch(err => {
    console.log(err);
    alert('Sorry, there was an error generating data. Please try again later.');
    return false;
  });
  console.log(response);
  if (!response || response.data.success === false) {
    return false
  } else return true;
}

/***********  Channel Functions ***********/

// Get WhatsApp instance
async function getInstance() {
  const response = await axios.get(`${url}/channels/whatsapp`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  })
  return response.data;
}

// Create a new WhatsApp instance
async function createInstance() {
  const response = await axios.post(`${url}/channels/whatsapp`, {}, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error creating WhatsApp instance. Please try again or contact support.');
  });
  return response.data;
}

// Delete a WhatsApp instance
async function disconnectInstance() {
  const response = await axios.delete(`${url}/channels/whatsapp`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error disconnecting WhatsApp instance. Please try again or contact support.');
  });
  return response.data;
}

// Provision a new phone number
async function provisionNumber(data) {
  const response = await axios.post(`${url}/channels/provision-number`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error requesting phone number. Please try again or contact support.');
  });
  return response.data;
}

// Connect SMS Channel
async function enableSMS() {
  const response = await axios.post(`${url}/channels/enable/sms`, {}, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error requesting SMS instance. Please try again or contact support.');
  });
  return response.data;
}

// Connect WhatsApp Channel
async function enableWhatsApp(data) {
  const response = await axios.post(`${url}/channels/enable/whatsapp`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error requesting WhatsApp instance. Please try again or contact support.');
  });
  return response.data;
}

// Connect Voice Channel
async function enableVoice() {
  const response = await axios.post(`${url}/channels/enable/voice`, {}, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error requesting Voice instance. Please try again or contact support.');
  });
  return response.data;
}

export {
  login,
  getISV,
  addClient,
  register,
  forgotPassword,
  resetPassword,
  getClientSettings,
  updateClientSettings,
  checkIntegrations,
  updateIntegrations,
  createPayment,
  getAvailabilities,
  updateAvailabilities,
  squareOauth,
  revokeSquare,
  requestAIAccess,
  getMessages,
  addData,
  getData,
  removeData,
  removeMessageFromData,
  getDashboard,
  getConversations,
  getConversation,
  sendMessage,
  unpauseAccount,
  getCalls,
  markCallRead,
  likeCall,
  dislikeCall,
  getInfo,
  addInfo,
  uploadInfo,
  deleteInfo,
  generateInfo,
  getInstance,
  createInstance,
  disconnectInstance,
  provisionNumber,
  enableSMS,
  enableWhatsApp,
  enableVoice,
};