<template>
  <div class="glass-card" :style="{ opacity: isVisible ? 1 : 0, transform: `translateY(${isVisible ? 0 : 20}px)` }">

    <div class="chart-container">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'CallActivity',
  props: {
    callData: {
      type: Array,
      default: () => [
        { date: 'Mon', calls: 0 },
        { date: 'Tue', calls: 0 },
        { date: 'Wed', calls: 0 },
        { date: 'Thu', calls: 0 },
        { date: 'Fri', calls: 0 },
        { date: 'Sat', calls: 0 },
        { date: 'Sun', calls: 0 },
      ]
    }
  },
  data() {
    return {
      isVisible: false,
      chart: null
    };
  },
  mounted() {
    // Set up the chart after the component is mounted
    this.setupChart();

    // Trigger animation on mount with a slight delay
    setTimeout(() => {
      this.isVisible = true;
    }, 200);
  },
  methods: {
    setupChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');

      // Get data from callData
      const labels = this.callData.map(item => item.date);
      const data = this.callData.map(item => item.calls);

      // Create gradient for area fill
      const gradient = ctx.createLinearGradient(0, 0, 0, 250);
      gradient.addColorStop(0, 'rgba(14, 165, 233, 0.2)'); // Light blue with opacity
      gradient.addColorStop(1, 'rgba(14, 165, 233, 0.02)'); // Almost transparent

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: 'calls',
            data: data,
            fill: true,
            backgroundColor: gradient,
            borderColor: '#2596be', // Sky blue color
            borderWidth: 2.5,
            tension: 0.35, // Slightly smoother curve
            pointBackgroundColor: 'rgb(14, 165, 233)',
            pointBorderColor: 'white',
            pointBorderWidth: 2,
            pointRadius: 0, // Hide points by default
            pointHoverRadius: 6,
            pointHitRadius: 10 // Larger hit area for tooltip activation
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false, // No vertical gridlines
                drawBorder: false
              },
              ticks: {
                color: 'rgb(100, 116, 139)', // Slate-500
                font: {
                  family: "'Inter', 'system-ui', sans-serif",
                  size: 12
                },
                padding: 10
              },
              border: {
                display: false
              }
            },
            y: {
              min: 0,
              max: Math.max(...this.callData.map(item => item.calls)) > 0
                ? Math.max(...this.callData.map(item => item.calls)) * 1.2
                : 10, // Default max value when all calls are zero
              grid: {
                color: 'rgba(226, 232, 240, 0.6)', // Very light gray
                lineWidth: 1,
                drawBorder: false
              },
              ticks: {
                color: 'rgb(100, 116, 139)', // Slate-500
                font: {
                  family: "'Inter', 'system-ui', sans-serif",
                  size: 12
                },
                padding: 10,
                stepSize: Math.max(...this.callData.map(item => item.calls)) > 0
                  ? Math.ceil(Math.max(...this.callData.map(item => item.calls)) / 5)
                  : 2, // Default step size when all calls are zero
              },
              border: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'white',
              titleColor: 'rgb(15, 23, 42)', // Slate-900
              bodyColor: 'rgb(14, 165, 233)', // Sky-500
              titleFont: {
                size: 16,
                weight: 'bold',
                family: "'Inter', 'system-ui', sans-serif"
              },
              bodyFont: {
                size: 14,
                family: "'Inter', 'system-ui', sans-serif"
              },
              borderColor: 'rgba(226, 232, 240, 0.5)',
              borderWidth: 1,
              cornerRadius: 8,
              padding: 12,
              displayColors: false,
              caretSize: 0,
              caretPadding: 20,
              callbacks: {
                title: function (tooltipItems) {
                  return tooltipItems[0].label;
                },
                label: function (context) {
                  return `calls : ${context.parsed.y}`;
                },
                labelTextColor: function () {
                  return 'rgb(14, 165, 233)'; // Sky-500 for the value text
                }
              }
            }
          },
          interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
          },
          elements: {
            point: {
              // Show points only for specific indices
              radius: function (context) {
                // Show point for Friday (index 4 in your data)
                return context.dataIndex === 4 ? 5 : 0;
              }
            }
          }
        }
      });
    }
  },
  beforeUnmount() {
    // Clean up the chart when the component is unmounted
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 300px;
  /* 64 * 4px = 256px */
  position: relative;
}
</style>