<template>
  <div class="dashboard" v-if="!loading">
    <h1>Dashboard</h1>
    <p id="subtitle">Your call activity at a glance</p>

    <!-- Metrics Cards -->
    <div class="metrics-grid">
      <div class="metric-card">
        <div class="metric-header">
          <span>Total Calls</span>
          <Phone />
        </div>
        <div class="metric-values">
          <div class="metric-value">{{ totalCalls }}</div>
          <div class="metric-trend" :class="totalCallsChange >= 0 ? 'positive' : 'negative'">
            {{ totalCallsChange >= 0 ? '↑' : '↓' }} {{ Math.abs(totalCallsChange) }}%</div>
        </div>
      </div>

      <div class="metric-card">
        <div class="metric-header">
          <span>Messages</span>
          <MessageSquare />
        </div>
        <div class="metric-values">
          <div class="metric-value">{{ messages }}</div>
          <div class="metric-trend" :class="messagesChange >= 0 ? 'positive' : 'negative'">
            {{ messagesChange >= 0 ? '↑' : '↓' }} {{ Math.abs(messagesChange) }}%</div>
        </div>
      </div>

      <div class="metric-card">
        <div class="metric-header">
          <span>Avg. Call Time</span>
          <Timer />
        </div>
        <div class="metric-values">
          <div class="metric-value">{{ formatTime(averageDuration) }}</div>
          <div class="metric-trend" :class="averageDurationChange >= 0 ? 'positive' : 'negative'">
            {{ averageDurationChange >= 0 ? '↑' : '↓' }} {{ Math.abs(averageDurationChange) }}%</div>
        </div>
      </div>

      <div class="metric-card">
        <div class="metric-header">
          <span>Unique Callers</span>
          <Users />
        </div>
        <div class="metric-values">
          <div class="metric-value">{{ uniqueCallers }}</div>
          <div class="metric-trend" :class="uniqueCallersChange >= 0 ? 'positive' : 'negative'">
            {{ uniqueCallersChange >= 0 ? '↑' : '↓' }} {{ Math.abs(uniqueCallersChange) }}%</div>
        </div>
      </div>

      <!-- <div class="metric-card">
        <div class="metric-header">
          <span>Minutes Saved</span>
          <Clock />
        </div>
        <div class="metric-values">
          <div class="metric-value">{{ Math.floor(totalDurationMins) }} mins</div>
          <div class="metric-trend" :class="totalDurationChange >= 0 ? 'positive' : 'negative'">
            {{ totalDurationChange >= 0 ? '↑' : '↓' }} {{ Math.abs(totalDurationChange) }}%</div>
        </div>
      </div> -->
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Call Activity Chart -->
      <div class="call-activity-section">
        <h2>Call Activity</h2>
        <p>Call volume over the last 7 days</p>
        <CallActivity :callData="weekCalls" />
      </div>

      <!-- Recent Calls -->
      <div class="recent-calls-section">
        <h2>Recent Calls</h2>
        <div class="call-list">
          <div v-for="(call, index) in recentCalls" :key="index" class="call-item" @click="this.$router.push(`/calls?call=${call.id}`)">
            <div class="call-icon">
              <PhoneIncoming />
            </div>
            <div class="call-details">
              <div class="call-header">
                <div class="caller-name">{{ call.user_name || 'Unknown' }}</div>
                <div class="call-status">Completed</div>
              </div>
              <div class="call-info">
                +{{ call.user_number }} <span>•</span> {{ getTimeAgo(call.date) }} <span>•</span> {{
                  formatTime(call.duration) }}
              </div>
            </div>
            <div class="call-arrow">
              <i class="right-arrow"></i>
            </div>
          </div>

          <div v-if="recentCalls.length === 0" class="call-item">
            <div class="call-details">
              <div class="call-header">
                <p>No recent calls</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-spinner" v-else>
    <i class="fas fa-spinner"></i>
  </div>
</template>

<script>
import CallActivity from '@/components/CallActivity.vue';
import { getDashboard } from '@/app/admin-routes';
import {
  Phone,
  MessageSquare,
  Clock,
  Timer,
  PhoneIncoming,
  Users,
} from 'lucide-vue-next';

export default {
  name: 'Dashboard',
  components: {
    CallActivity,
    Phone,
    MessageSquare,
    Clock,
    Timer,
    PhoneIncoming,
    Users,
  },
  data() {
    return {
      loading: true,
      weekCalls: [
        { date: 'Mon', calls: 0 },
        { date: 'Tue', calls: 0 },
        { date: 'Wed', calls: 0 },
        { date: 'Thu', calls: 0 },
        { date: 'Fri', calls: 0 },
        { date: 'Sat', calls: 0 },
        { date: 'Sun', calls: 0 },
      ],
      totalCalls: 0,
      totalCallsChange: 0,
      averageDuration: 0,
      averageDurationChange: 0,
      totalDurationMins: 0,
      totalDurationChange: 0,
      uniqueCallers: 0,
      uniqueCallersChange: 0,
      recentCalls: [],
      messages: 0,
      messagesChange: 0,
    };
  },
  mounted() {
    this.fetchCallData();
  },
  methods: {
    async fetchCallData() {
      try {
        const response = await getDashboard();
        // You might want to merge the response data with your initial data
        // or replace it entirely depending on your API response
        if (response) {
          this.weekCalls = response.weekCalls || this.weekCalls;
          this.totalCalls = response.totalCalls || this.totalCalls;
          this.totalCallsChange = response.totalCallsChange || this.totalCallsChange;
          this.averageDuration = response.averageDuration || this.averageDuration;
          this.averageDurationChange = response.averageDurationChange || this.averageDurationChange;
          this.totalDurationMins = response.totalDurationMins || this.totalDurationMins;
          this.totalDurationChange = response.totalDurationChange || this.totalDurationChange;
          this.uniqueCallers = response.uniqueCallers || this.uniqueCallers;
          this.uniqueCallersChange = response.uniqueCallersChange || this.uniqueCallersChange;
          this.recentCalls = response.recentCalls || this.recentCalls;
          this.messages = response.messages || this.messages;
          this.messagesChange = response.messagesChange || this.messagesChange;
        }
      } catch (error) {
        console.error('Error fetching call data:', error);
      } finally {
        this.loading = false;
      }
    },
    formatTime(seconds) {
      if (!seconds) return '0:00';
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
    },
    getTimeAgo(date) {
      const now = new Date();
      const callDate = new Date(date);
      const diffInSeconds = Math.floor((now - callDate) / 1000);
      if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  padding: 0 20px;
  box-sizing: border-box;
}

.dashboard-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}

h1 {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 2.1em;
  width: 100%;
  text-align: left;
  color: #333 !important;
}

#subtitle {
  margin-left: 20px;
  margin-bottom: 40px;
  margin-top: 0;
  font-size: 1.1em;
  color: #6C727F;
  width: 100%;
  text-align: left;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 24px;
  max-width: 1400px;
  margin: 0 auto;
}

.metric-card {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6b7280;
  margin-bottom: 8px;
}

.metric-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-value {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}

.metric-trend {
  font-size: 14px;
}

.positive {
  color: #10b981;
}

.negative {
  color: #ef4444;
}

.main-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-width: 1400px;
  margin: 20px auto;
}

.call-activity-section,
.recent-calls-section {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 700px;
}

.call-activity-section h2,
.recent-calls-section h2 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  text-align: left;
}

.call-activity-section p {
  color: #6b7280;
  margin-top: 0;
  margin-bottom: 16px;
  text-align: left;
}

.call-list {
  display: flex;
  flex-direction: column;
}

.call-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
}

.call-item:last-child {
  border-bottom: none;
}

.call-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
  background-color: #e0f2fe;
  color: #2596be;
  padding: 5px;
}

.call-details {
  flex: 1;
}

.call-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.caller-name {
  font-weight: 500;
  font-size: 1.1em;
}

.call-status {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 12px;
  background-color: #ecfdf5;
  color: #10b981;
}

.call-info {
  color: #6b7280;
  font-size: 13px;
  text-align: left;

  span {
    margin: 0 10px;
    display: inline-block;
  }
}

.call-arrow {
  margin-left: 12px;
}

@media (max-width: 768px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-content {
    grid-template-columns: 1fr;
  }
}
</style>